import React, { useState } from 'react';

import { ShopContext } from "../../context/shop";
const Installation = ({handleInfoChange}) => {
    const { checkout, withInstallment } = React.useContext(ShopContext);
    const [tooltip, toggleToolTip] = useState(false);
    const handleToolTip = () => toggleToolTip(!tooltip);
    
    return (
        <div className="my-3">
            <h5 className="fs-obviously">Installation</h5>
            <div className="row g-2 align-items-center mt-4">
                <div className="col-12 ">
                    <div className="card rounded-0">
                        <div className="card-body border">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <p className="card-title">Contact</p>
                                </div>
                                <div className="col-6 col-md col-sm-8">
                                    <p className="card-subtitle text-truncate">{checkout.shipping_information.email ?? "Not Provided"}</p>
                                </div>
                                <div className="col text-end">
                                    <button className="btn btn-link change-info-link btn-sm card-text text-dark p-0 m-0" onClick={handleInfoChange}>change</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body border">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <p className="card-title">Ship to</p>
                                </div>
                                <div className="col-6 col-md col-sm-8">
                                    <p className="card-subtitle text-truncate">{checkout.shipping_information.address_1 ?? "Not Provided"}</p>
                                </div>
                                <div className="col text-end">
                                    <button className="btn btn-link change-info-link btn-sm card-text text-dark p-0 m-0" onClick={handleInfoChange}>change</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 ">
                    <p className="text-dark small mt-5">We know it can be hard for you to install it yourself, so we are happy to help you with installation by sending professional technicians over for free.</p>
                </div>
                <div className="col-12 ">
                    <div className="card rounded-0">
                        <div className="card-body border">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <div className="form-check">
                                        <input onChange={(e) => withInstallment(true)} 
                                            className="form-check-input form-installation-check-input" type="radio" name="installationFeature" id="installationFeature1" defaultChecked />
                                        <label className="form-check-label text-truncate" htmlFor="installationFeature1">
                                            I need professional installation
                                        </label>
                                    </div>
                                </div>
                                <div className="col text-end">
                                    <p className="small card-text position-relative d-inline" onClick={handleToolTip}
                                    onMouseEnter={handleToolTip} onMouseLeave={handleToolTip}>
                                        <img className="img-fluid" src={require('../../assets/icons/ask_me_icon.svg').default} width={18} alt={"Ask me!"} />
                                        {
                                            tooltip &&
                                            <span className="position-absolute translate-middle badge bg-primary p-2 text-white fw-normal" style={{
                                                zIndex: '2500',
                                                top: '-50%'
                                            }}>
                                                To be calculated at time of shipment
                                                <span className="visually-hidden">To be calculated at time of shipment</span>
                                            </span>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body border">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <div className="form-check">
                                        <input onChange={(e) => { withInstallment(false) }} className="form-check-input form-installation-check-input" type="radio" name="installationFeature" id="installationFeature2" />
                                        <label className="form-check-label text-truncate" htmlFor="installationFeature2">
                                            I can do it myself
                                        </label>
                                    </div>
                                </div>
                                <div className="col text-end">
                                    <p className="small card-text"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Installation;