import React from 'react';
import "./WavyTextAnimation.css";

const WavyTextAnimation = ({ animation }) => {
   
    return (
        <div className={`${animation && 'wavy'} 
        position-relative text-uppercase text-nowrap small 
        m-0 fs-obviously-extended wavy-text d-flex align-items-center justify-content-center`}>
            <span style={{ '--i': 1 }}>M</span>
            <span style={{ '--i': 2 }}>A</span>
            <span style={{ '--i': 3 }}>D</span>
            <span style={{ '--i': 4 }}>E</span>
            <span className="mx-1" style={{ '--i': 5 }}> </span>
            <span style={{ '--i': 6 }}>F</span>
            <span style={{ '--i': 7 }}>O</span>
            <span style={{ '--i': 8 }}>R</span>
            <span className="mx-1" style={{ '--i': 9 }}> </span>
            <span style={{ '--i': 10 }}>M</span>
            <span style={{ '--i': 11 }}>O</span>
            <span style={{ '--i': 12 }}>T</span>
            <span style={{ '--i': 13 }}>I</span>
            <span style={{ '--i': 14 }}>O</span>
            <span style={{ '--i': 15 }}>N</span>
        </div>
    );
};

export default WavyTextAnimation;