import React from 'react';
import { FloatingComponent, Footer, NavigationBar, PreOrderExtended } from '../../components';
import { ShopContext } from '../../context/shop';

const Products = ({ loginStatus }) => {

    const { resetCart, checkout } = React.useContext(ShopContext);
    React.useEffect(() => {
        if (checkout?.status === "PENDING" || checkout?.status === "SUCCESS") {
            resetCart();
        }
    }, [checkout, resetCart]);

    console.log(loginStatus);

    return (
        <React.Fragment>
            <FloatingComponent>
                <NavigationBar disableButtons={true} showCart={true} showLogout={loginStatus} />
                <div className='container my-5'>
                    <div className='row align-items-center justify-content-center py-5'>
                        <div className='col-12'>
                            <PreOrderExtended />
                        </div>
                    </div>
                </div>
                <Footer />
            </FloatingComponent>
        </React.Fragment>
    );
};

export default Products;