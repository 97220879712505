import React, { createContext, useState, useEffect, useContext } from 'react';


const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const ScreenContext = createContext(getWindowDimensions)

const ScreenProvider = ({ children }) => {
    
    const [screenSize, updateScreenSize] = useState(getWindowDimensions);
    useEffect(() => {
        const handleResize = () => {
            const { innerWidth: width, innerHeight: height } = window;
            updateScreenSize({
                width,
                height
            });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, [screenSize])

    return (
        <ScreenContext.Provider value={screenSize}>
            {children}
        </ScreenContext.Provider>
    );
}

export default ScreenProvider;
export const useScreen = () => useContext(ScreenContext);