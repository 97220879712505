import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from "yup";

import { FloatingComponent, Footer, NavigationBar } from '../../components';
import { ShopContext } from '../../context/shop';
import { AuthContext } from '../../context/auth';

const Login = () => {

    const { resetCart, checkout } = useContext(ShopContext);
    const { Authenticate, setLoginStatus, feedback } = useContext(AuthContext)
    const history = useHistory();

    React.useEffect(() => {
        if (checkout?.status === "PENDING" || checkout?.status === "SUCCESS") {
            resetCart();
        }
    }, [checkout, resetCart]);

    const loginSchema = Yup.object().shape({
        username: Yup.string().trim().required("Please enter your username"),
        password: Yup.string().trim().required("Please enter your password"),
    });

    return (
        <React.Fragment>
            <FloatingComponent>
                <NavigationBar disableButtons={true} />
                <div className="container my-5">
                    <div className="row justify-content-center py-5">
                        {
                            feedback.message && feedback.message?.length > 0 ?
                                <div className="col-12">
                                    <div className={`alert alert-${feedback.type} rounded-0 fixed-top`} role="alert" style={{
                                        maxHeight: '150px',
                                        overflowY: 'auto',
                                    }}>
                                        {
                                            feedback.message.map((data, index) => (
                                                <span key={index} className='fw-bold'> {JSON.stringify(data?.error || data)} </span>
                                            ))
                                        }
                                    </div>
                                </div> : null

                        }
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="card shadow my-5">
                                <div className="card-title text-center border-bottom">
                                    <h2 className="p-3">Login</h2>
                                </div>
                                <div className="card-body">
                                    <Formik
                                        initialValues={{
                                            username: "",
                                            password: ""
                                        }}
                                        validationSchema={loginSchema}
                                        onSubmit={(values, { setSubmitting }) => {
                                            setSubmitting(true);

                                            Authenticate('login', values).then(response => {

                                                console.log(response);
                                                localStorage.setItem("Token", response.data.access)
                                                localStorage.setItem("RefreshToken", response.data.refresh)

                                                setTimeout(() => {

                                                    setLoginStatus(true, [])
                                                    history.replace('/choose-product')

                                                }, 1000)

                                            }).catch(error => {
                                                // console.error(error.response.data);
                                                setLoginStatus(false, [error?.response?.data || "Login failed. User might not be available with this credentails!"])
                                                setSubmitting(false)

                                            })
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            /* and other goodies */
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-4">
                                                    <label htmlFor="username" className="form-label">Username</label>
                                                    <input type="text" className="form-control" id="username" name="username" autoFocus={true}
                                                        helpertext={touched.username && errors.username}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.username} />
                                                    <div className={`invalid-feedback ${errors.username && touched.username && 'd-block'}`}>
                                                        {errors.username && touched.username && (
                                                            <small className="small">
                                                                * {errors.username}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                    <input type="password" className="form-control" id="password" name='password'
                                                        helpertext={touched.password && errors.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.password} />
                                                    <div className={`invalid-feedback ${errors.password && touched.password && 'd-block'}`}>
                                                        {errors.password && touched.password && (
                                                            <small className="small">
                                                                * {errors.password}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="d-grid">
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </FloatingComponent>
        </React.Fragment>
    );
};

export default Login;