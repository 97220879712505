import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';

import { ShopContext } from '../../context/shop';
import { AuthContext } from '../../context/auth';

import { TickerExtended } from '../TickerExtended/TickerExtended';
import { publicAxios } from '../../config/axiosIn';

import "./NavigationBar.css";

const NavigationBar = ({ disableButtons = false, showCart = false, showLogout = false }) => {

    const { checkout } = useContext(ShopContext)
    const { Logout } = useContext(AuthContext);

    const [changeNavbar, setNavbar] = React.useState(window.scrollY >= 175);
    const [text, setText] = React.useState([]);

    const history = useHistory();

    React.useEffect(() => {
        const handleBackground = () => {
            if (window.scrollY >= 175) {
                setNavbar(true);
            }
            else {
                setNavbar(false);
            }
        }

        window.addEventListener('scroll', handleBackground);
        return () => window.removeEventListener('scroll', handleBackground);

    }, [])

    React.useEffect(() => {
        publicAxios
            .get("/api/content/top_header_text")
            .then((res) => {
                if (res?.data?.value !== "" && res?.data?.value !== "N/A" && res?.data?.value !== null) {
                    setText(res.data.value.split("*"))
                }
            })
            .catch((erorr) => {
                console.error(erorr);
            });
    }, []);

    return (
        <React.Fragment>
            <nav className={`navbar navbar-expand-md navbar-light fixed-top  ${changeNavbar ? 'bg-light' : 'bg-transparent'}`}>
                <div className="container-fluid px-sm-5 navbar-padding">
                    <a className="navbar-brand" href="\">
                        <img src={require('./../../assets/icons/frame-abreviated-logo-black.png').default} alt="site logo"
                            className="img-fluid navbar-icon" />
                    </a>

                    {
                        disableButtons && <div className="nav-item">
                            {
                                (disableButtons && showCart) &&
                                <button onClick={() => history.push("/place-order")} className="btn btn-circle btn-lg frame-cart mx-4 position-relative">
                                    <span className="position-absolute start-100 translate-middle badge rounded-pill text-dark"
                                        style={{ backgroundColor: '#DFFF61', top: '25%' }}>

                                        {checkout?.products && checkout.products.length}
                                    </span>
                                </button>
                            }
                            {
                                (disableButtons && showLogout) &&
                                <button onClick={() => {
                                    Logout()
                                    history.replace('/')
                                }} className="btn btn-sm btn-outline-primary mx-1">
                                    Logout
                                </button>
                            }
                        </div>
                    }
                    {
                        !disableButtons &&
                        <React.Fragment>
                            <div className="collapse navbar-collapse">
                                <ul className="navbar-nav ms-auto align-items-center">
                                    <li className="nav-item mx-3">
                                        <Link className="btn nav-link btn-sm text-dark" to="scroll-to-reformer" smooth={true} duration={5}>
                                            the reformer
                                        </Link>
                                    </li>
                                    <li className="nav-item mx-3">
                                        <Link className="btn nav-link btn-sm text-dark" to="scroll-to-know-yourself" smooth={true} duration={5}>
                                            the benefits
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="nav-item">
                                {/* <Link className="btn btn-primary btn-sm px-4" to="sign-up-form" smooth={true} duration={5}
                            style={{
                                fontSize: 'smaller'
                            }}>
                            SIGN UP
                        </Link> */}
                                <Link className="btn btn-primary btn-sm px-4" to="pre-order-modal" smooth={true} duration={5}
                                    style={{
                                        fontSize: 'smaller'
                                    }}>
                                    {/* Pre-Order */}
                                    JOIN THE WAITLIST
                                </Link>
                                {/* <button onClick={() => history.push("/place-order")} className="btn btn-circle btn-lg frame-cart mx-1 position-relative">
                            <span className="position-absolute start-100 
                                translate-middle badge rounded-pill text-dark"
                                style={{ backgroundColor: '#DFFF61', top: '25%' }}>

                                {checkout?.products && checkout.products.length}
                            </span>
                        </button> */}
                            </div>
                        </React.Fragment>
                    }
                </div>
            </nav>
            {
                (!disableButtons && text && text.length > 0) && <TickerExtended customClass={"marquee-wrapper"} text={text} />
            }
        </React.Fragment>
    );
};

export default NavigationBar;