import React, { useState, useEffect } from "react";
import { ShopContext } from "../../context/shop";
import { useHistory } from "react-router-dom";
import ModalExtended from "../ModalExtended/ModalExtended";
import { useScreen } from "../../context/screen";

const PreOrder = ({ open, onClose }) => {

  // const [varient, setVarient] = useState(1);
  // let history = useHistory();
  // const screen = useScreen();

  // const handleVarient = (varint) => {
  //   setVarient(varint);
  // };

  // const { fetchAllProducts, products, addItemToCheckout, fetchCheckout } = React.useContext(ShopContext);

  // useEffect(() => {
  //   fetchAllProducts();
  // }, [fetchAllProducts]);

  // useEffect(() => {
  //   fetchCheckout();
  // }, [fetchCheckout]);

  // useEffect(() => {
  //   if (products && products.length > 0) {
  //     setVarient(products[0].variants[0].id);
  //   }
  // }, [products]);

  // const handlePreOrder = async () => {
  //   await addItemToCheckout(varient);
  //   history.push("/place-order");
  // }

  return (
    <ModalExtended open={open} onClose={onClose}>
      <PreOrderExtended />
    </ModalExtended>
  );
};


export const PreOrderExtended = () => {


  const [varient, setVarient] = useState(1);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const screen = useScreen();

  const handleVarient = (varint) => {
    setVarient(varint);
  };

  const { fetchAllProducts, products, addItemToCheckout, fetchCheckout } = React.useContext(ShopContext);

  useEffect(() => {
    fetchAllProducts();
  }, [fetchAllProducts]);

  useEffect(() => {
    fetchCheckout();
  }, [fetchCheckout]);

  useEffect(() => {
    if (products && products.length > 0) {
      setVarient(products[0].variants[0].id);
      setLoading(false);
    }
  }, [products]);

  const handlePreOrder = async () => {
    await addItemToCheckout(varient);
    history.push("/place-order");
  }


  return (
    <React.Fragment>
      {(products && products.length > 0) ? (
        <div className="card">
          <div className="row g-0">
            <div className="col-xl-7">
              <div className={`align-items-stretch justify-content-center ${screen.width >= 1200 ? 'd-flex h-100' : ''}`}>
                {varient === products[0].variants[1].id && (
                  <img
                    src={
                      require("../../assets/resources/prod-varient-black.jpg")
                        .default
                    }
                    className={`img-fluid rounded-start`}
                    alt="Product In White"
                    style={{ objectPosition: "right center" }}
                  />
                )}
                {varient === products[0].variants[0].id && (
                  <img
                    src={
                      require("../../assets/resources/prod-varient-white.jpg")
                        .default
                    }
                    className={`img-fluid rounded-start`}
                    alt="Product In Black"
                    style={{ objectPosition: "right center" }}
                  />
                )}
              </div>
            </div>
            <div className="col-xl-5">
              <div className="card-body my-5">
                <h5 className="card-title fs-5 mb-3 fs-obviously">
                  {products[0].name}
                </h5>
                <p className="card-text text-dark">
                  The at-home, digitally-connected Pilates reformer
                </p>
                <p className="fw-bold small">
                  Dimensions w/ Screen Displayed: 92” L x 25.5” W x 36” H  <br />
                  Dimensions w/ Screen Stored: 92” L x 25.5” W x 13” H
                </p>
                <div className="text-gray-800 font-bold mb-4 fs-obviously-wide">
                  <span className="text-2xl">$</span>
                  <span className="text-3xl">
                    {
                      varient === products[0].variants[0].id
                        ? products[0].discounted_price
                        : products[0].discounted_price
                    }
                  </span>
                  <span className="text-gray-500 font-medium"> USD</span>

                  <p className="mx-2 my-0 text-muted 
                    text-decoration-line-through fs-obviously-normal d-inline">
                    $
                    {
                      varient === products[0].variants[0].id
                        ? products[0].actual_price
                        : products[0].actual_price
                    }
                  </p>
                </div>
                <p
                  className="card-text fs-obviously"
                  style={{ fontSize: "0.85rem" }}
                >
                  Select Color
                </p>
                <div className="d-flex align-items-center">
                  <button
                    className={`btn btn-sm px-4 me-1 rounded-0 ${varient === products[0].variants[0].id ? "btn-dark" : "btn-outline-dark"}`}
                    onClick={() => handleVarient(products[0].variants[0].id)}
                    style={{ fontSize: 11 }}
                  >
                    {products[0].variants[0].color}
                  </button>
                  <button
                    className={`btn btn-sm px-4 ms-1 rounded-0 ${varient === products[0].variants[1].id ? "btn-secondary" : "btn-outline-secondary"}`}
                    onClick={() => handleVarient(products[0].variants[1].id)}
                    style={{ fontSize: 11 }}
                  >
                    {products[0].variants[1].color}
                  </button>
                </div>
                <button
                  to="/place-order"
                  onClick={handlePreOrder}
                  className="btn btn-primary btn-sm px-5 mt-5"
                >
                  Pre-Order Now
                </button>
              </div>
              <div className="card-footer bg-transparent border-0 py-0">
                <p className="text-muted " style={{ fontSize: ".75rem" }}>
                  *White glove service and delivery calculated at the time of shipment <br />
                  *Frame subscription sold seperately. <br />
                  We will notify you about finance options at the time of shipment <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      ) :
        <div className="container-fluid d-flex align-items-center justify-content-center">
          <div className="text-center">
            {
              loading ? <p className="h5 fw-bold text-capitalize">
                Please wait... Fetching Products
              </p> : <p className="h4 fw-bold fs-obviously text-capitalize">
                Currently, No Product Found!
              </p>
            }
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default PreOrder;