import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import { ShopContext } from '../../context/shop';
import { useScreen } from '../../context/screen';

const Payment = ({ nextStep }) => {

    const { checkout, createCheckout, preCheckout, setOrderID, setErrors } = React.useContext(ShopContext);
    const screen = useScreen();
    const [hasBilling, setHasBilling] = useState(true);

    const FormSchema = (hasBillingInformation) => Yup.object().shape({
        number: Yup.string().trim().required("Please enter your card number").test('credit_card_number_length', '', function(value) {
            const { path, createError } = this;
            if(value) {
                const customValue = value.replace(/\s/g, "");
                if(customValue && (customValue.length < 14 || customValue.length > 18)){
                    return createError({ path, message: `Card Number must be between 14 - 18 characters long.` })
                }
            } else {
                return;
            }

            return true;
        }),
        month: Yup.string().trim().min(2, "Please enter expiry month in MM format").max(2, "Please enter expiry month in MM format").required("Please enter your card expiry month"),
        year: Yup.string().trim().min(2, "Please enter expiry year in YY format").max(2, "Please enter expiry year in YY format").required("Please enter your card expiry year"),
        cvv: Yup.string().trim().min(3, "CVV must be between 3 - 4 characters long.").max(4, "CVV must be between 3 - 4 characters long.").required("Please enter your card cvv number"),

        first_name: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().min(2, "First name must be between 2 - 50 characters long.").max(50, "First name must be between 2 - 50 characters long.").required("Please enter your first name"),
        last_name: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().min(2, "Last name must be between 2 - 50 characters long.").max(50, "Last name must be between 2 - 50 characters long.").required("Please enter your last name"),
        address_1: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().required("Please enter your correct billing address").max(30, "Address should not exceed 30 characters!"),
        address_2: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().max(30, "Address should not exceed 30 characters!"),
        city: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().required("Please enter your city name"),
        state: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().required("Please enter your state name"),
        email: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().email("Please enter a valid email address").required("Please enter your email address"),
        phone: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().required("Please enter your phone number").min(10, "The phone number must be between 10 - 12 characters long.").max(12, "The phone number must be between 10 - 12 characters long."),
        country: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().required('Please select a your country'),
        postal_code: hasBillingInformation ? Yup.string().trim() : Yup.string().trim().required("Please enter your Zip code/Postal code").min(5, "Postal code must be between 5 - 9 characters long").max(9, "Postal code must be between 5 - 9 characters long"),
    });

    const [schema, setSchema] = useState(() => FormSchema(hasBilling));

    useEffect(() => {
        setSchema(FormSchema(hasBilling));
    }, [hasBilling]);

    const correctNumber = (event) => {
        if (!(/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab|Shift|End|Home|Ctrl)/.test(event.key))) {
            event.preventDefault();
            return;
        }
    }

    const handleExpiryDate = (event) => {
        var input = event.target;
        if (/^[0-9]{0,2}$/.test(input.value)) {
            if (input.name === "month") {
                if (input.value.length === 1 && parseInt(input.value) <= 9 && parseInt(input.value) > 1) {
                    input.value = "0" + input.value;
                }
                else {
                    if (input.value.length >= 2 && (parseInt(input.value) > 12 || parseInt(input.value) === 0)) {
                        input.value = "12"
                    }
                    event.preventDefault();
                    return;
                }
            } else {
                let minYear = (new Date()).getFullYear() - 2000;
                let maxYear = (new Date()).getFullYear() - 2000 + 25;
                if (input.value.length >= 2 && (parseInt(input.value) < minYear)) {
                    input.value = minYear
                }

                if (input.value.length >= 2 && parseInt(input.value) > maxYear) {
                    input.value = maxYear
                }

                event.preventDefault();
                return;
            }
        }
        else {
            input.value = input.value.substring(0, 2);
        }
    }

    const handleCardNumber = (event) => {
        var input = event.target;
        input.value = formatCardNumber(input.value)
    }

    const formatCardNumber = value => {
        let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
        let matches = v.match(/\d{4,18}/g);
        let match = (matches && matches[0]) || "";
        let parts = [];
        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }

        if (parts.length) {
            return parts.join(" ");
        } else {
            return value;
        }
    };

    const [countryCode, setCountryCode] = React.useState();
    const listOfCodes = ["+1", "+44", "+52"]
    const getCountryCode = value => {
        let updateCode = null;
        switch (value) {
            case "United State Of America":
            case "Canada":
                updateCode = "+1";
                break;
            case "United Kingdom":
                updateCode = "+44";
                break;
            case "Mexico":
                updateCode = "+52";
                break;
            default:
                updateCode = null;
                break;
        }

        setCountryCode(updateCode);
    }


    React.useEffect(() => {
        getCountryCode(checkout.billing_information?.country);
    }, [checkout])

    return (
        <Formik
            initialValues={{
                number: "",
                month: "",
                year: "",
                cvv: "",
                BillingAddress: "true",
                first_name: checkout.billing_information?.first_name || "",
                last_name: checkout.billing_information?.last_name || "",
                address_1: checkout.billing_information?.address_1 || "",
                address_2: checkout.billing_information?.address_2,
                city: checkout.billing_information?.city || "",
                country: checkout.billing_information?.country || "",
                state: checkout.billing_information?.state || "",
                postal_code: checkout.billing_information?.postal_code || "",
                email: checkout.billing_information?.email || "",
                phone: checkout.billing_information?.phone?.replace(listOfCodes.find(code => checkout.billing_information.phone.includes(code)), "") || "",
            }}

            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                let data = values;
                const card = {
                    number: data.number.replace(/\s/g, ""),
                    expiry: data.month + data.year,
                    cvv: data.cvv
                }

                const billingExist = data.BillingAddress === "true";
                const billingDetails = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    address_1: data.address_1,
                    address_2: data.address_2 === "" ? undefined : data.address_2,
                    city: data.city,
                    country: data.country,
                    state: data.state,
                    postal_code: data.postal_code,
                    email: data.email,
                    phone: data.phone
                }
                createCheckout(card, billingExist, billingDetails, preCheckout.free_subscription).then(response => {
                    if (response && (response.status >= 200) && (response.status <= 299)) {
                        setOrderID(response.data.unique_id);
                        nextStep();
                        setSubmitting(false);
                    }
                }).catch(err => {
                    if(err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                        setErrors(err.response.data.error.message)
                    } else {
                        setErrors("There's an error while processing your transaction with provided details.")
                    }
                    setSubmitting(false);
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="my-3">
                        <h5 className="fs-obviously">Payment Information</h5>
                        <div className="row g-2 align-items-center mt-4">
                            <div className="col-12 ">
                                <div className="row g-2 align-items-start mt-4">
                                    <div className="col-6 my-2">
                                        <input
                                            type="text"
                                            className="form-control py-2 "
                                            name="number"
                                            autoFocus={true}
                                            helpertext={touched.number && errors.number}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.number || ""}
                                            onInput={handleCardNumber}
                                            onKeyDown={correctNumber}
                                            placeholder="Card Number"
                                        />
                                        <div className={`invalid-feedback ${errors.number && touched.number && 'd-block'}`}>
                                            {errors.number && touched.number && (
                                                <small className="small">
                                                    * {errors.number}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-3 my-2">
                                        <input
                                            type="text"
                                            className="form-control py-2"
                                            placeholder="MM"
                                            name="month"
                                            helpertext={touched.month && errors.month}
                                            onChange={handleChange}
                                            onKeyDown={correctNumber}
                                            onInput={handleExpiryDate}
                                            onBlur={handleBlur}
                                            value={values.month || ""}
                                        />
                                        <div className={`invalid-feedback ${errors.month && touched.month && 'd-block'}`}>
                                            {errors.month && touched.month && (
                                                <small className="small">
                                                    * {errors.month}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-3 my-2">
                                        <input
                                            type="text"
                                            className="form-control py-2"
                                            placeholder="YY"
                                            name="year"
                                            helpertext={touched.year && errors.year}
                                            onChange={handleChange}
                                            onKeyDown={correctNumber}
                                            onInput={handleExpiryDate}
                                            onBlur={handleBlur}
                                            value={values.year || ""}
                                        />
                                        <div className={`invalid-feedback ${errors.year && touched.year && 'd-block'}`}>
                                            {errors.year && touched.year && (
                                                <small className="small">
                                                    * {errors.year}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <input
                                            type="text"
                                            className="form-control py-2"
                                            placeholder="CVV"
                                            name="cvv"
                                            helpertext={touched.cvv && errors.cvv}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={correctNumber}
                                            value={values.cvv || ""}
                                        />
                                        <div className={`invalid-feedback ${errors.cvv && touched.cvv && 'd-block'}`}>
                                            {errors.cvv && touched.cvv && (
                                                <small className="small">
                                                    * {errors.cvv}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 className="fs-obviously mt-5">Billing Address</h5>
                        <div className="row g-2 align-items-center my-4">
                            <div className="col-12 ">
                                <div className="card rounded-0">
                                    <div className="card-body border">
                                        <div className="row align-items-center">
                                            <div className="col-12">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input form-installation-check-input"
                                                        type="radio"
                                                        name="BillingAddress"
                                                        id="BillingAddress1"
                                                        helpertext={touched.BillingAddress && errors.BillingAddress}
                                                        onChange={(event) => {
                                                            handleChange(event)
                                                            setHasBilling(true)
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={"true"}
                                                        checked={values.BillingAddress === "true"}
                                                    />
                                                    <label className="form-check-label text-truncate" htmlFor="BillingAddress1">
                                                        Same as shipping address
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body border">
                                        <div className="row align-items-center">
                                            <div className="col-12">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input form-installation-check-input"
                                                        type="radio"
                                                        name="BillingAddress"
                                                        id="BillingAddress2"
                                                        helpertext={touched.BillingAddress && errors.BillingAddress}
                                                        onChange={(event) => {
                                                            handleChange(event)
                                                            setHasBilling(false)
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={"false"}
                                                        checked={values.BillingAddress === "false"}
                                                    />
                                                    <label className="form-check-label text-truncate" htmlFor="BillingAddress2">
                                                        Use a different billing address
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            values.BillingAddress === "false" &&
                                            <React.Fragment>
                                                <div className="row g-2 align-items-start mt-4">
                                                    <div className="col-6 my-2">
                                                        <input
                                                            type="text"
                                                            className="form-control py-2 "
                                                            name="first_name"
                                                            autoFocus={true}
                                                            helpertext={touched.first_name && errors.first_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.first_name}
                                                            placeholder="First Name"
                                                        />
                                                        <div className={`invalid-feedback ${errors.first_name && touched.first_name && 'd-block'}`}>
                                                            {errors.first_name && touched.first_name && (
                                                                <small className="small">
                                                                    * {errors.first_name}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 my-2">
                                                        <input
                                                            type="text"
                                                            className="form-control py-2"
                                                            placeholder="Last Name"
                                                            name="last_name"
                                                            helpertext={touched.last_name && errors.last_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.last_name}
                                                        />
                                                        <div className={`invalid-feedback ${errors.last_name && touched.last_name && 'd-block'}`}>
                                                            {errors.last_name && touched.last_name && (
                                                                <small className="small">
                                                                    * {errors.last_name}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 my-2">
                                                        <input
                                                            type="text"
                                                            className="form-control py-2"
                                                            placeholder="Billing Address"
                                                            name="address_1"
                                                            helpertext={touched.address_1 && errors.address_1}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.address_1}
                                                        />
                                                        <div className={`invalid-feedback ${errors.address_1 && touched.address_1 && 'd-block'}`}>
                                                            {errors.address_1 && touched.address_1 && (
                                                                <small className="small">
                                                                    * {errors.address_1}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <input
                                                            type="text"
                                                            className="form-control py-2"
                                                            placeholder="Apartment, suite, etc. (optional)"
                                                            name="address_2"
                                                            helpertext={touched.address_2 && errors.address_2}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.address_2}
                                                        />
                                                        <div className={`invalid-feedback ${errors.address_2 && touched.address_2 && 'd-block'}`}>
                                                            {errors.address_2 && touched.address_2 && (
                                                                <small className="small">
                                                                    * {errors.address_2}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-4 my-2">
                                                        <select
                                                            className="form-select py-2"
                                                            aria-label="Country/Region"
                                                            name="country"
                                                            helpertext={touched.country && errors.country}
                                                            onChange={(event) => {
                                                                getCountryCode(event.target.value)
                                                                handleChange(event)
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.country ?? "0"}>
                                                            <option value="" label="Country/Region" />
                                                            <option value="United State Of America"> United State Of America </option>
                                                            <option value="Canada"> Canada </option>
                                                            {/* <option value="United Kingdom"> United Kingdom </option>
                                                            <option value="Mexico"> Mexico </option> */}
                                                        </select>
                                                        <div className={`invalid-feedback ${errors.country && touched.country && 'd-block'}`}>
                                                            {errors.country && touched.country && (
                                                                <small className="small">
                                                                    * {errors.country}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-8 my-2">
                                                        <div className="input-group mb-2">
                                                            {
                                                                countryCode &&
                                                                <div className="input-group-prepend">
                                                                    <div className="input-group-text py-2">{countryCode}</div>
                                                                </div>
                                                            }
                                                            <input
                                                                disabled={!countryCode}
                                                                type="text"
                                                                className="form-control py-2"
                                                                placeholder="Phone Number (without dashes)"
                                                                name="phone"
                                                                helpertext={touched.phone && errors.phone}
                                                                onKeyDown={correctNumber}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.phone}
                                                            />
                                                            <div className={`invalid-feedback ${errors.phone && touched.phone && 'd-block'}`}>
                                                                {errors.phone && touched.phone && (
                                                                    <small className="small">
                                                                        * {errors.phone}
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <input
                                                            type="text"
                                                            className="form-control py-2"
                                                            name="city"
                                                            helpertext={touched.city && errors.city}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.city}
                                                            placeholder="City"
                                                        />
                                                        <div className={`invalid-feedback ${errors.city && touched.city && 'd-block'}`}>
                                                            {errors.city && touched.city && (
                                                                <small className="small">
                                                                    * {errors.city}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <input
                                                            type="text"
                                                            className="form-control py-2"
                                                            name="email"
                                                            placeholder="Email"
                                                            helpertext={touched.email && errors.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                        />
                                                        <div className={`invalid-feedback ${errors.email && touched.email && 'd-block'}`}>
                                                            {errors.email && touched.email && (
                                                                <small className="small">
                                                                    * {errors.email}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <div className="row g-2 align-items-start">
                                                            <div className="col-6">
                                                                <input
                                                                    type="text"
                                                                    className="form-control py-2"
                                                                    placeholder="State"
                                                                    name="state"
                                                                    helpertext={touched.state && errors.state}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.state}
                                                                />
                                                                <div className={`invalid-feedback ${errors.state && touched.state && 'd-block'}`}>
                                                                    {errors.state && touched.state && (
                                                                        <small className="small">
                                                                            * {errors.state}
                                                                        </small>
                                                                    )}
                                                                </div>

                                                            </div>
                                                            <div className="col-6">
                                                                <input
                                                                    type="text"
                                                                    className="form-control py-2"
                                                                    placeholder="Zip Code"
                                                                    name="postal_code"
                                                                    helpertext={touched.postal_code && errors.postal_code}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.postal_code}
                                                                />
                                                                <div className={`invalid-feedback ${errors.postal_code && touched.postal_code && 'd-block'}`}>
                                                                    {errors.postal_code && touched.postal_code && (
                                                                        <small className="small">
                                                                            * {errors.postal_code}
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className={`btn btn-primary px-5 w-100 ${screen.width <= 991 ? "fixed-bottom" :
                            "position-absolute start-50 translate-middle-x"} ${values.BillingAddress === 'true' ? 'bottom-0' : ''}`}
                        type="submit"
                        disabled={isSubmitting || (checkout?.products && checkout?.products.length === 0)}>
                        complete pre-order
                    </button>
                </form>
            )}
        </Formik>
    );
};

export default Payment;