import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useScreen } from '../../context/screen';
import { ShopContext } from '../../context/shop';
import { ProductCard } from '../../components';

import Shipping from './Shipping';
import Payment from './Payment';
import Installation from './Installation';

import './index.css';

const Order = () => {
    const history = useHistory();
    const screen = useScreen();
    const { checkout, products, resetCart, preCheckout, setStatusSuccess, errors } = useContext(ShopContext);
    const [step, setCurrentStep] = useState(0);

    useEffect(() => {
        if (checkout?.status === "PENDING" || checkout?.status === "SUCCESS") {
            resetCart();
        }
    }, [checkout, resetCart]);

    useEffect(() => {
        return () => {
            if (checkout.id) {
                setStatusSuccess();
            }
        }
    }, [checkout, setStatusSuccess])

    const getTotal = () => {

        if (!Array.isArray(checkout.products)) {
            return 0;
        }

        let subscription = preCheckout.free_subscription ? 0 : checkout.no_of_subscriptions * 39

        return checkout.products.reduce((currentValue, item) => {
            return currentValue + (parseInt(item.quantity) * parseInt(products[0].discounted_price))
        }, 0) + subscription;
    };

    const DueAmount = () => {
        if (!Array.isArray(checkout.products)) {
            return 0;
        }

        return checkout.products.reduce((currentValue, item) => {
            return currentValue + (parseInt(item.quantity) * 100)
        }, 0)
    }

    const handlePaymentSteps = async () => {
        if (step === 3) {
            return;
        }
        setCurrentStep(step + 1);
    };

    const handleBackClick = () => {
        if (step === 0) {
            history.replace("/");
        } else {
            setCurrentStep(step - 1);
        }
    };

    if ((checkout.products && checkout.products.length === 0)) {
        return (
            <div className="container-fluid page-bg d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <p className="h4 fw-bold fs-obviously text-capitalize">
                        Your Cart is currently empty!
                    </p>
                    <p className="fs-6 m-0">
                        Before proceed to checkout you must add a product in your cart.
                    </p>
                    <button className="btn btn-outline-secondary px-4 my-5 btn-sm" onClick={handleBackClick}
                        style={{ fontSize: 'small' }}>
                        Go Back
                    </button>
                </div>
            </div>
        );
    } else 
    {

        return (
            <div className="container-fluid page-bg">
                <div className="container px-0">
                    {
                        errors && errors.length > 0 && step > 0 && step < 3 ?
                            <div className="row">
                                <div className="col-12">
                                    <div className="alert alert-danger rounded-0 fixed-top" role="alert" style={{
                                        maxHeight: '150px',
                                        overflowY: 'auto'
                                    }}>
                                        {errors}
                                    </div>
                                </div>
                            </div> : null
                    }
                    <div className="row py-3">
                        {step <= 2 ? (
                            <div className="col-12">
                                <button type="button" className="px-3 px-1 btn btn-labeled btn-mat btn-link btn-mat-link-default btn-sm"
                                    onClick={handleBackClick}>
                                    <span className="btn-label">
                                        <img
                                            src={require("../../assets/icons/prev-icon.svg").default}
                                            alt="Move Left"
                                            className="me-2 img-fluid"
                                        />
                                    </span>
                                    Back
                                </button>
                            </div>
                        ) : (
                            <div className="col-12">
                                <div className="row align-items-center px-3">
                                    <div className="col-auto">
                                        <p className="fw-bold mb-0">Order Number: #{checkout.id} </p>
                                    </div>
                                    <div className="col">
                                        <div className="line-heading"></div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {step <= 2 && (
                            <div className="col-12 py-3 ">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li
                                            className={`breadcrumb-item ${step === 0 && "active-step"}`}
                                        >
                                            Shipping
                                        </li>
                                        <li
                                            className={`breadcrumb-item ${step === 1 && "active-step"}`}
                                        >
                                            Installation
                                        </li>
                                        <li
                                            className={`breadcrumb-item ${step === 2 && "active-step"}`}
                                        >
                                            Payment
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        )}
                        <div className="col-12">
                            <div className="row align-items-stretch">
                                <div className="col-lg-6 my-2 position-relative">
                                    {step === 0 && <Shipping nextStep={handlePaymentSteps} />}
                                    {step === 1 && <Installation handleInfoChange={handleBackClick} />}
                                    {step === 2 && <Payment nextStep={handlePaymentSteps} />}
                                    {(step === 1) && (
                                        <button
                                            disabled={step === 0 || (checkout.products && checkout.products.length === 0)}
                                            className={`btn btn-primary px-5 w-100 ${screen.width <= 991 ? "fixed-bottom" :
                                                "position-absolute bottom-0 start-50 translate-middle-x"}`}
                                            onClick={handlePaymentSteps}
                                        >
                                            Next
                                        </button>
                                    )}
                                    {step === 3 && (
                                        <React.Fragment>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 mb-4" style={{
                                                        backgroundImage: 'linear-gradient(180deg, #D9B2FF -30.33%, #FF916E 57.02%, #DFFF61 137.38%)'
                                                    }}>
                                                        <h3 className="fs-obviously-wide pt-3 pb-4">
                                                            Welcome to Frame’s Core Team!
                                                        </h3>
                                                        <p className=" text-dark">
                                                            The countdown to reformer pilates at home starts now!{" "}
                                                            <br /> <br />
                                                            Check your email in the next 48 hours for an order
                                                            confirmation and additional details on expected delivery
                                                            time. <br /> <br />
                                                            As a member of our Core Team, you’ll also be the first
                                                            to receieve updates about additional product promotions,
                                                            class content sneak peeks, as well as branded swag
                                                            giveaways. <br />
                                                        </p>
                                                    </div>
                                                    <div className="col-12 my-3">
                                                        <h6 className="fs-obviously">
                                                            Follow Us on Social Media
                                                        </h6>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <a href="https://www.facebook.com/Frame-Reformer-276338430872027/" className="btn border-0 btn-circle me-3"
                                                                    rel="noopener noreferrer" target="_blank">
                                                                    <img
                                                                        src={
                                                                            require("../../assets/icons/fb-blue.svg")
                                                                                .default
                                                                        }
                                                                        alt="Facebook Icon"
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="col-auto">
                                                                <a href="https://twitter.com/framereformer" className="btn border-0 btn-circle me-3">
                                                                    <img
                                                                        src={
                                                                            require("../../assets/icons/twitter-blue.svg")
                                                                                .default
                                                                        }
                                                                        alt="Twitter Icon"
                                                                        className="social-img"
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="col-auto">
                                                                <a href="https://instagram.com/framereformer" className="btn border-0 btn-circle me-3"
                                                                    rel="noopener noreferrer" target="_blank">
                                                                    <img
                                                                        src={
                                                                            require("../../assets/icons/insta-blue.svg")
                                                                                .default
                                                                        }
                                                                        alt="Instagram Icon"
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <h6 className="fs-obviously my-4">
                                                            Purchase Information
                                                        </h6>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p className="fw-bold mb-0">Contact Information</p>
                                                                <p className="text-dark fw-normal">
                                                                    {checkout?.shipping_information?.email}
                                                                    {/* amberlee@gmail.com */}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p className="fw-bold mb-0">Billing Method</p>
                                                                <p className="text-dark fw-normal">
                                                                    Credit Card: {checkout?.card?.number?.replace(/^(..).*(...)$/, "$1*****$2")}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p className="fw-bold mb-0">Shipping Address</p>
                                                                <p className="text-dark fw-normal">
                                                                    {checkout?.shipping_information?.address_1}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p className="fw-bold mb-0">Billing Address</p>
                                                                <p className="text-dark fw-normal">
                                                                    {checkout?.billing_information?.address_1}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p className="fw-bold mb-0">Shipping Method</p>
                                                                <p className="text-dark fw-normal">To be calculated at time of shipment</p>
                                                            </div>
                                                        </div>
                                                        <p className=" mt-4">
                                                            Have Questions?{" "}
                                                            <a className="link link-dark" href="mailto:info@framefitness.com" rel="noopener noreferrer" target="_blank"> Contact Us</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className="col-lg-6 my-2">
                                    {
                                        checkout.id &&
                                        <button onClick={() => history.replace("/")}
                                            className="btn-mat btn-link btn-mat-link-default btn-sm small px-3"> Return Home </button>
                                    }
                                    <div className="card border-0 rounded-0">
                                        <div className="card-body">
                                            {checkout &&
                                                checkout.products &&
                                                checkout.products.map((item, index) => {
                                                    return (
                                                        <ProductCard
                                                            item={item}
                                                            key={index}
                                                            products={products}
                                                            discount={preCheckout.discount_percentage}
                                                            isSelected={step > 0 ? true : false}
                                                        />
                                                    );
                                                })}
                                            {
                                                checkout.products.length > 0 &&
                                                <div className="border-bottom border-muted" />
                                            }

                                            <ProductCard
                                                type={"plan"}
                                                subscription={checkout.no_of_subscriptions}
                                                isFreeSubs={preCheckout.free_subscription}
                                                isSelected={step > 0 ? true : false}
                                            />

                                            <div className="border-bottom border-muted" />

                                            <div className="my-5">
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td>Subtotal</td>
                                                            <th scope="row" className="text-end">
                                                                ${getTotal()} <span className="text-gray-500 font-medium text-sm">USD</span>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>Shipping &amp; Handling</td>
                                                            <td className="text-end">To be calculated at time of shipment</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Installation</td>
                                                            <td className="text-end">To be calculated at time of shipment</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Taxes</td>
                                                            <td className="text-end">Free</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total</td>
                                                            <th scope="row" className="text-end">
                                                                ${getTotal()} <span className="text-gray-500 font-medium text-sm">USD</span>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>Due Today</td>
                                                            <td className="text-end">${DueAmount()} <span className="text-gray-500 font-medium text-sm">USD</span></td>
                                                        </tr>
                                                    </tbody>
                                                    <caption>*$100 is a non refundable deposit</caption>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
};

export default Order;