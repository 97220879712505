import React, { useState, createContext } from 'react';
import { publicAxios, privateAxios } from "../config/axiosIn";
import jwt_decode from "jwt-decode";

const AuthProvider = ({ children }) => {

    const [login, updateLogin] = useState(undefined);
    const [feedback, setFeedback] = useState({
        type: 'info',
        message: []
    });

    React.useEffect(() => {
        let loggedInUser = localStorage.getItem("Token");
        let status = false;
        if (loggedInUser) {
            let decoded = jwt_decode(loggedInUser);
            console.log(decoded);
            if (new Date((decoded.exp * 1000)) > new Date()) {
                status = true;
                console.log(status);
            }
        }

        updateLogin(status)

    }, []);

    const Authenticate = async (type, data) => {
        if (type === 'login') {
            return await publicAxios.post(`/api/token`, data)

        } else {
            console.error("No declaration found with provided parameters");
            return;
        }

    }

    const setLoginStatus = (status = null, anyFeedback = []) => {

        if (anyFeedback.length > 0) {
            setFeedback({
                type: 'danger',
                message: anyFeedback
            })

            setTimeout(() => {
                setFeedback({
                    type: 'info',
                    message: []
                })
            }, 2000)
        }

        if (status !== null) {
            updateLogin(status)
        }
    }

    const Logout = () => {

        localStorage.removeItem('Token');
        localStorage.removeItem('RefreshToken');

        setFeedback([])
        updateLogin(false)
    }

    const updateToken = async () => {
        return await privateAxios.post("/api/token/refresh", {
            refresh: localStorage.getItem("RefreshToken"),
        });
    }

    return (
        <AuthContext.Provider value={{
            isLogin: login,
            feedback: feedback,
            Authenticate: Authenticate,
            setLoginStatus: setLoginStatus,
            updateToken: updateToken,
            Logout: Logout

        }}>
            {children}
        </AuthContext.Provider>
    );
};


export const AuthContext = createContext({});
export default AuthProvider;