// import axios from 'axios';

// const baseURL = `https://apis.frame.meganoslab.com`;
// // const baseURL = `https://dev-apis.frame.meganoslab.com`;
// export const axiosIns = axios.create({ baseURL });
// axiosIns.defaults.headers.common['Content-Type'] = 'application/json';
// axiosIns.defaults.headers.common['Accept'] = 'application/json';
// axiosIns.defaults.crossDomain = true;


import axios from 'axios';
let isAlreadyFetchingAccessToken = false;

let subscribers = [];

const URL = `https://apis.frame.meganoslab.com`

export const publicAxios = axios.create({
    baseURL: URL,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
});
// publicAxios.defaults.crossDomain = true;

export const privateAxios = axios.create({
    baseURL: URL,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
});

// privateAxios.defaults.crossDomain = true;
privateAxios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const access_token = localStorage.getItem("Token");
        if (error.response.status === 401 && access_token) {
            return ResetTokenAndReattemptRequest(error);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);

privateAxios.interceptors.request.use((config) => {
    const token = localStorage.getItem("Token");
    if (token) { // or get it from localStorage
        config.headers["Authorization"] = "Bearer " + token
    }
    return config
})


export async function ResetTokenAndReattemptRequest(error) {
    try {
        const { response: errorResponse } = error;
        const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((access_token) => {
                errorResponse.config.headers.Authorization = "Bearer " + access_token;
                resolve(axios(errorResponse.config));
            });
        });

        if (!isAlreadyFetchingAccessToken) {

            isAlreadyFetchingAccessToken = true;
            await publicAxios
                .post("/api/token/refresh", {
                    refresh: localStorage.getItem("RefreshToken"),
                })
                .then(function (response) {
                    localStorage.setItem("Token", response.data.access);
                })
                .catch(function (error) {
                    return Promise.reject(error);
                });
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(localStorage.getItem("Token"));
        }

        return retryOriginalRequest;

    } catch (err) {
        return Promise.reject(err);
    }
}

function onAccessTokenFetched(access_token) {
    subscribers.forEach((callback) => callback(access_token));
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback);
}