import React, { useContext } from 'react';
import { ShopContext } from "../../context/shop";

const ProductCard = ({ item, type, isSelected = false, discount = 0, products, subscription, isFreeSubs = false, isReceipt = false }) => {

    const [quantity, setQuantity] = React.useState(item?.quantity || 0);
    const { updateCheckoutItems, removeItemfromCheckout, updateSubscription } = useContext(ShopContext)

    if (isReceipt && item?.variant && type !== 'plan') {
        item.product_variant = item.variant === 'sunrise' ? 1 : 2
    }

    if (type === 'plan') {
        return (
            <div className="row g-3 align-items-center py-5">
                <div className="col-md-auto text-center">
                    <img src={require('../../assets/resources/product-plan.png').default} alt="Product Plan Monthly" />
                </div>
                <div className="col-md">
                    <div className="row g-1 align-items-stretch">
                        <div className="col">
                            <h5 className="card-title  mb-3">Subscription</h5>
                            <ul style={{ fontSize: '0.75rem' }} className="ps-3 ">
                                <li className="my-1">Charges of $39/mo required upon product activation</li>
                                <li className="my-1">Personalized training recommendations</li>
                                <li className="my-1">One login for everyone in your home</li>
                            </ul>
                        </div>
                        <div className="col text-end">
                            {/* <p className="fs-5 ">$39/mo</p> */}
                            <p className="fs-5 ">{
                                isFreeSubs ? "Free" : "$39/mo"
                            }</p>
                            <div className="d-flex align-items-center justify-content-end">
                                {
                                    isSelected ?
                                        <p className="m-0 fw-normal text-dark">{`QTY: ${subscription}`}</p> :
                                        <React.Fragment>
                                            <button className="btn btn-sm fw-bold me-2 btn-light"
                                                onClick={() => updateSubscription(subscription - 1)}
                                                disabled={subscription === 0}> - </button>
                                            <p className="m-0 mx-2 fw-bold text-muted">{subscription}</p>
                                            <button className="btn btn-sm fw-bold ms-2 btn-light"
                                                onClick={() => updateSubscription(subscription + 1)}
                                            > + </button>
                                        </React.Fragment>
                                }
                            </div>
                            {
                                !isSelected &&
                                <button onClick={() => removeItemfromCheckout(null, true)}
                                    className="btn-mat btn-link btn-mat-link-default btn-sm small px-0"> Remove </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="row g-3 align-items-center py-5">
                <div className="col-md-auto text-center">
                    {
                        item?.product_variant === 2 ? (
                            <img style={{ objectFit: 'cover', objectPosition: 'right center' }} width={100} height={100} src={require('../../assets/resources/prod-varient-black.jpg').default} alt="Selected Product Midnight" />
                        ) : (
                            <img style={{ objectFit: 'cover', objectPosition: 'right center' }} width={100} height={100} src={require('../../assets/resources/prod-varient-white.jpg').default} alt="Selected Product Sunrise" />
                        )
                    }
                </div>
                <div className="col-md">
                    <div className="row g-1 align-items-stretch">
                        <div className="col">
                            <h5 className="card-title " style={{ maxWidth: 200 }}>Pilates Reformer — Pre-Order</h5>
                            <div className="d-flex align-items-center">
                                <p className="me-3 small text-secondary text-decoration-line-through "> ${products[0].actual_price} </p>
                                <p className="ms-3 text-muted " style={{ fontSize: '0.75rem' }}>{discount}% off applied</p>
                            </div>
                            {
                                isSelected ?
                                    <p className="m-0 mx-2 fw-normal text-dark">{item.product_variant === 2 ? "Midnight" : item.product_variant === 1 ? "Sunrise" : ""}</p> :
                                    <React.Fragment>
                                        <div className="d-flex align-items-center">
                                            {
                                                item.product_variant === 2 ? (
                                                    <button className={`btn btn-sm px-4 me-1 btn-outline-dark`}
                                                        disabled={true} style={{ fontSize: 11 }}>Midnight</button>
                                                ) : (
                                                    <button className={`btn btn-sm px-4 ms-1 btn-outline-secondary`}
                                                        disabled={true} style={{ fontSize: 11 }}>Sunrise</button>
                                                )
                                            }

                                        </div>
                                    </React.Fragment>

                            }
                        </div>
                        <div className="col text-end">
                            {/* <p className="fs-5 ">${products[0].discounted_price}</p> */}
                            <div className="text-gray-800">
                                <span className="text-2xl fs-5">$</span>
                                <span className="text-3xl fs-5"> {products[0].discounted_price} </span>
                                <span className="text-gray-500 font-medium text-sm">USD</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                {
                                    isSelected ?
                                        <p className="m-0 fw-normal text-dark">{`QTY: ${quantity}`}</p> :
                                        <React.Fragment>
                                            <button className="btn btn-sm fw-bold me-2 btn-light"
                                                onClick={() => {
                                                    updateCheckoutItems({ product_variant: item.product_variant, quantity: quantity - 1 })
                                                    setQuantity(quantity - 1)
                                                }}
                                                disabled={quantity === 1}> - </button>
                                            <p className="m-0 mx-2 fw-bold text-muted">{quantity}</p>
                                            <button className="btn btn-sm fw-bold ms-2 btn-light"
                                                onClick={() => {
                                                    updateCheckoutItems({ product_variant: item.product_variant, quantity: quantity + 1 })
                                                    setQuantity(quantity + 1)
                                                }}> + </button>
                                        </React.Fragment>
                                }
                            </div>
                            {
                                !isSelected &&
                                <button onClick={() => removeItemfromCheckout(item.product_variant, false)}
                                    className="btn-mat btn-link btn-mat-link-default btn-sm small px-0"> Remove </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ProductCard;