import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { Login } from './pages/Login';
// import { Home } from './pages/Home';
import { Checkout } from './pages/Checkout';
// import { Receipt } from './pages/Receipt';
import { Products } from './pages/Products';

import ScreenProvider from './context/screen';
import ShopProvider from './context/shop';

import { AuthContext } from './context/auth';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

const App = () => {
  const { isLogin } = useContext(AuthContext)

  return (
    <React.Fragment>
      <BrowserRouter>
        <ShopProvider>
          <ScreenProvider>
            <div className="container-fluid main-wrapper">
              {
                isLogin !== undefined && (
                  <Switch>
                    <PublicRoute path="/login" isAuthenticated={isLogin}>
                      <Login />
                    </PublicRoute>

                    <PrivateRoute path="/choose-product" isAuthenticated={isLogin}>
                      <Products loginStatus={isLogin} />
                    </PrivateRoute>

                    <PrivateRoute path="/place-order" isAuthenticated={isLogin}>
                      <Checkout />
                    </PrivateRoute>

                    {/* <Route path="/place-order/:id" component={Receipt} exact /> */}
                    <Redirect path="/" to={isLogin ? '/choose-product' : '/login'} />
                    <Route path="*" render={() => (<Redirect to={isLogin ? '/choose-product' : '/login'} />)} />
                  </Switch>
                )
              }
            </div>
          </ScreenProvider>
        </ShopProvider>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;