import React from 'react';
import ModalExtended from '../ModalExtended/ModalExtended';
import { useScreen } from '../../context/screen';
import "./Footer.css";

const Footer = () => {
    
    const screen = useScreen();
    const [open, setOpen] = React.useState(false);
    const [dataID, updateDataID] = React.useState(undefined);

    const handleModal = (dataId = undefined) => {
        if (dataId !== undefined) {
            updateDataID(dataId)
        }
        setOpen(!open);
    }

    const TermsofService = (
        <div className="card-body border-0 rounded-0">
            <p className="h5 fw-bold card-title" tabIndex={-1}>Terms of Service</p>
            <p className="h6 fw-normal text-secondary card-subtitle">Last updated September 27, 2021</p>

            <p className="card-text my-5">
                Please read these terms of service ("Terms of Service ") carefully before using this website (“website”) operated by Frame Innovative Technologies Corp. (“Frame”, "us", “we", or "our"), or any services provided by or made accessible by Frame through or via this website, any exercise equipment that Frame makes available, or otherwise, as this legal document describes your responsibilities, Frame’s liability, and governs your use of this website and any such services or products. We reserve the right to change these Terms of Service at any time. If you do not wish to be bound by these Terms of Service, you must not access or use our website or the Services (defined below).  The Frame Privacy Policy forms a part of these Terms of Service.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold">Intellectual Property Acknowledgement</p>
            <p className="card-text mb-5">
                The materials and information displayed on this website (collectively, together with any software, the website, web pages, cloud services, content, and other materials and services available therein and thereby, or that Frame makes available on, by or through any exercise equipment or otherwise, and successor service(s) thereto, the “Services”), including without limitation all intellectual property and other proprietary rights therein, are and will remain owned by Frame or its licensors or suppliers, and all copyright and other intellectual property and proprietary rights in the Services are reserved. All product and company names and other trademarks on the website or Services are trademarks or registered trademarks of Frame or their other respective owners. You may not use our trade names, trademarks, service marks or logos in connection with any product or service that is not ours, or in any manner that is likely to cause confusion. Nothing contained on the Services should be construed as granting any right to use any trade names, trademarks, service marks or logos without the express prior written consent of the owner. Except as expressly stated, use of any trademarks of a third party on, through or by the Services does not imply any affiliation with or endorsement by the respective third party.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold">Age Limitation and Conditions of Use</p>
            <p className="card-text">
                Individuals under 15 years old are not permitted to use or access the Services, except with consent or a parent/guardian, so long as the parent/guardian is present at all times during such use.
            </p>
            <p className="card-text mb-5">
                Frame may, in its sole discretion, refuse to offer the Services to any person or entity and change the eligibility criteria at any time. You are solely responsible for ensuring compliance with all laws, rules and regulations applicable to you, and the right to access and use the Services is revoked where these Terms of Service or use of the Services is prohibited or conflicts with any applicable law, rule or regulation. Further, the Services are offered only for your personal, non-commercial use.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold">Disclaimers and Warnings</p>
            <p className="card-text">
                The content and other material, advice or information accessed, provided or made available by with or through the Services (“Content”) may include instructional videos, exercise routines, exercise records and other media. You acknowledge and agree that Frame is not a healthcare provider or fitness instructor; that the Services (including any recommendations and any information available through the Services that may be personalized) may not be appropriate for you, and do not constitute or replace medical advice; and that Frame is not responsible for any results that may (or may not) be obtained from the use of the Services. You acknowledge and agree that it is your sole responsibility to consult with your personal physician regarding any medical condition, impairment or disability that may prevent or limit your ability to use the Services. You further understand and agree that you are solely responsible for abiding by your personal physician’s recommendation as to any such medical restrictions, and for using the Services solely in accordance with your personal physician’s advice.
            </p>
            <p className="card-text mb-5">
                You understand that by using the Services, you may be exposed to a variety of hazards and risks, foreseen or unforeseen, including risks that arise as a result of entering a gym space or any other space or environment in which the Services are made available, performing physical activity, and/or using the equipment on or through which the Services are available. You understand that injuries or damage could occur by natural causes or activities of other persons, including the activities of other persons using such equipment, or other third parties (including third-party fitness instructors and other exercise participants), whether as a result of negligence or otherwise. To the fullest extent permitted by applicable law, you voluntarily assume all risk of loss, damage and injury to person or property that may arise from, or that is related to, acting on any Content or your use of the Services, whether such risk is known or unknown to you.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold">No Guarantees or Warranties</p>
            <p className="card-text mb-5">
                Frame cannot guarantee the validity of any Content, and Content is not intended as a substitute for professional advice, but is only provided and made available for educational and informational purposes. Developments in medical research may impact the Content, including without limitation, the health, fitness and nutritional advice that appears, is accessible, or is provided on, by or through the Services. No assurance can be given that the Content will always include the most recent findings or developments with respect to the particular material. While Frame tries to maintain the timeliness, integrity and security of the Content and Services, Frame makes no warranties or guarantees that the Content and Services are or will remain updated, complete, correct and secure, or that access to the Services will be uninterrupted. All Content and Services are provided or made available on an “AS IS,” “WHERE IS,” AND “WHERE AVAILABLE” basis, without any warranties of any kind, whether express, implied or statutory. FRAME EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE CONTENT AND SERVICES WHETHER EXPRESS OR IMPLIED, OF MERCHANTA BILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold">License to Use the Services</p>
            <p className="card-text mb-5">
                Subject to your compliance with these Terms of Service , and solely for so long as you are permitted by Frame to use the Services, we hereby permit you, on a limited, non-exclusive, revocable, non-transferable, non-sublicensable basis, to use the Services on Frame-branded equipment, solely for your personal, non-commercial use. You may not: i) reuse, republish (including republication on another website), or reprint Content or Services without Frame’s written consent, ii) sell, rent or otherwise sublicense any Content or Services, or iii) reproduce, duplicate, copy or otherwise exploit Content or Services for a commercial purpose; or iv) edit or otherwise modify any Content or Services. If you fail to comply with any of the Terms of Service, you must immediately cease using the Services.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold">Limitation of Liability</p>
            <p className="card-text">
                Under no circumstances will Frame be held responsible or liable in any way for, and you hereby waive and release Frame, to the maximum extent permitted by law, from, any claims, actions, suits, damages, losses, expenses, costs or liabilities whatsoever (including, without limitation, any incidental, consequential, or other direct or indirect damages for loss of profits, business interruption or loss of information; and, with respect to any physical injury or death) arising out of or resulting directly or indirectly from your use or inability to use or access the Services, this website or any websites linked to it, from your reliance on all or any portion of the Content, or any mistakes, errors, misrepresentations or omissions in the Content or Services, even if Frame has been advised of the possibility of such damages in advance. In particular, but without limitation, Frame accepts no liability in connection with any third-party websites which may be linked or accessible through Frame’s website or the Services, and Frame does not endorse or approve the contents of any such third-party site. You acknowledge that Frame will not incur any obligation to you under any legal theory (including negligence) as a result of your use of the Services. You agree that the releases in paragraph are binding upon you and your heirs, executors, administrators, successors and assigns.
            </p>
            <p className="card-text mb-5">
                These Terms of Service and Privacy Policy constitute the entire agreement between you and Frame in relation to your use of the Services, and supersede all previous agreements in respect of your use of the Services.
            </p>
        </div>
    );


    const PrivacyPolicy = (
        <div className="card-body border-0 rounded-0">
            <p className="h5 fw-bold card-title" tabIndex={-1}>Privacy Policy</p>
            <p className="h6 fw-normal text-secondary card-subtitle">Last updated September 29, 2021</p>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">General</p>
            <p className="card-text mb-5">
                This Privacy Policy sets out how Frame use and protect personal information that we may collect about you when you visit our website (the “Website”), correspond with us, or when you interact with our website, web pages, software, cloud services, content, and other materials and services available therein and thereby, or that Frame makes available on, by or through any exercise equipment or otherwise, and successor service(s) thereto, the “Services.” We use your Personal Information to provide you information about our products, our company, and our Services.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">What We Mean By Personal Information +</p>
            <p className="card-text mb-5">
                For purposes of this Privacy Policy, "Personal Information" means any information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular individual.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">What Information Do We Collect?</p>
            <p className="card-text">
                We may collect the following types of Personal Information about you:
            </p>
            <div className="card-text mb-5">
                <p className="d-flex align-items-top">
                    <span className="pe-2">(A)</span>
                    <span>Contact information such as your name, email address, telephone number(s), mailing address.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">(B)</span>
                    <span>Internet or other electronic network activity information, including logs of your interactions with our Website and your IP address.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">(C)</span>
                    <span>Information related to the Services such as age, frequency and length of workouts, time of workout, and exercises performed.</span>
                </p>
            </div>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Where Do We Collect Information From?</p>
            <p className="card-text">
                We collect Personal Information from the following types of sources:
            </p>
            <div className="card-text mb-5">
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>Information you voluntarily provide;</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>Information automatically collect via cookies, web logs, web beacons, and other analytics tools;</span>
                </p>
            </div>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">How Do We Use Your Information?</p>
            <p className="card-text">
                We collect your Personal Information for the following purposes:
            </p>
            <div className="card-text mb-5">
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>To respond to, process, and communicate with you about your requests, inquiries, and comments;</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>To monitor and improve the essential functioning and performance of the Site;</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>To protect against, identify and prevent fraud and other unlawful activity, claims and other liabilities; </span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>To comply with applicable legal requirements, industry standards, and our own policies.</span>
                </p>
            </div>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Links to Other Websites</p>
            <p className="card-text mb-5">
                Our Website may contain links to other websites of interest. However, once you have used these links to leave our Website, we do not have any control over third party websites. We cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this Privacy Policy. You should exercise caution and review the Privacy Policy applicable to the website in question.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Children</p>
            <p className="card-text mb-5">
                The Website and the Services are not intended for children under the age of 15. We do not knowingly or intentionally collect, sell, or process the Personal Information of minors.
            </p>


            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">How Do We Use Cookies?</p>
            <p className="card-text">
                Some of the features on the Website and the Services require the use of "cookies" - small text files that are stored on your computer's hard drive. We use cookies to measure which pages are being accessed, and which features are most frequently used. This enables us to continuously improve the Website to meet the needs of our visitors.
            </p>
            <p className="card-text">
                The following sets out how we may use different categories of cookies and your options for managing cookie settings:
            </p>
            <div className="table-responsive mb-5">
                <table className="table table-bordered align-middle table-nowrap">
                    <thead>
                        <tr>
                            <th scope="col">Type of Cookies</th>
                            <th scope="col">Description</th>
                            <th scope="col">Managing Settings</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Required cookies</th>
                            <td className="description">Required cookies enable you to navigate the Website and use its features, such as accessing secure areas of the Website and using our services. If you have chosen to identify yourself to us, we use cookies containing encrypted information to allow us to uniquely identify you. These cookies allow us to uniquely identify you when you are logged into the Website and to process your online transactions and requests.</td>
                            <td>Because required cookies are essential to operate the Website, there is no option to opt out of these cookies.</td>
                        </tr>
                        <tr>
                            <th scope="row">Performance cookies</th>
                            <td className="description">These cookies collect information about how you use our Website, including which pages you go to most often and if they receive error messages from certain pages. These cookies do not collect information that individually identifies you. Information is only used to improve how the Website functions and performs. From time-to-time, we may engage third parties to track and analyze usage and volume statistical information relating to individuals who visit the Website. We may also utilize Flash cookies for these purposes.</td>
                            <td>To learn how to opt out of performance cookies using your browser settings, <a className="card-link" rel="noopener noreferrer" target='_blank' href="https://www.aboutcookies.org/">click here</a>. To learn how to manage privacy and storage settings for Flash cookies, <a className="mx-0 card-link" rel="noopener noreferrer" target='_blank' href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">click here</a>.</td>
                        </tr>
                        <tr>
                            <th scope="row">Functionality cookies</th>
                            <td className="description">Functionality cookies allow our Website to remember information you have entered or choices you make (such as your username, language, or your region) and provide enhanced, more personal features. These cookies also enable you to optimize your use of the Website after logging in. These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customize. We may use local shared objects, also known as Flash cookies, to store your preferences or display content based upon what you view on the Site to personalize your visit.</td>
                            <td>To learn how to opt out of functionality cookies using your browser settings, <a className="card-link" rel="noopener noreferrer" target='_blank' href="https://www.aboutcookies.org/">click here</a>. To learn how to manage privacy and storage settings for Flash cookies, <a className="mx-0 card-link" rel="noopener noreferrer" target='_blank' href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">click here</a>.</td>
                        </tr>
                        <tr>
                            <th scope="row">Targeting or Advertising cookies</th>
                            <td className="description">From time-to-time, we may engage third parties to track and analyze usage and volume statistical information from individuals who visit the Website. We sometimes use cookies delivered by third parties to track the performance of our advertisements. For example, these cookies remember which browsers have visited the Website. By way of example, as you browse the Website, advertising cookies may be placed on your computer so that we can understand what you are interested in. Our advertising partners then enable us to present you with retargeted advertising on other sites based on your previous interaction with the Website. Third parties, with whom we partner to provide certain features on the Website or to display advertising based upon your web browsing activity, use Flash cookies to collect and store information. Flash cookies are different from browser cookies because of the amount of, type of, and how data is stored.</td>
                            <td>To learn more about these and other advertising networks and their opt out instructions, <a className="card-link" rel="noopener noreferrer" target='_blank' href="http://www.networkadvertising.org/choices/">click here</a>. To learn how to manage privacy and storage settings for Flash cookies, <a className="mx-0 card-link" rel="noopener noreferrer" target='_blank' href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">click here</a>.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Changes to This Notice</p>
            <p className="card-text mb-5">
                This Privacy Policy may change from time to time, effective from the date mentioned in the updated version of the Privacy Policy. Please check the Website periodically to review such changes in the Privacy Policy. We may email periodic reminders of our agreements and policies in the event of a change.
            </p>


            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Contact Us</p>
            <p className="card-text">
                If you have any questions or concerns about this Privacy Policy or about our privacy or data security practices, please contact us.
            </p>

            <p className="card-subtitle mb-3 fw-bold text-uppercase">CALIFORNIA CONSUMER PRIVACY ACT PRIVACY NOTICE ADDENDUM</p>
            <p className="card-text mb-5">
                This California Consumer Privacy Act Addendum provides additional details regarding our collection, use, and disclosure of Personal Information relating to California residents, pursuant to the California Consumer Privacy Act of 2018 (“CCPA”).  This Addendum does not apply to our job applicants, employees, contractors, owners, directors, or officers where the Personal Information we collect about those individuals relates to their current, former, or potential role at our company.  For purposes of this Addendum, “Personal Information” means information that identifies, relates to, or could reasonably be linked with a particular California resident or household.
            </p>


            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Collection of Personal Information</p>
            <p className="card-text">
                We plan to collect the following categories of Personal Information, as listed in the CCPA:
            </p>
            <div className="card-text">
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Identifiers.”</span>Identifiers such as name, postal address (including billing and shipping addresses), telephone number, email address, username, unique identifier assigned to your Equipment, and certain Personal Information from your social media account that you share with us if you connect your social media account to your Services account with us, and IP address.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Customer Records Information.”</span>Personal information as defined in the California customer records law, such as name, contact information, and credit and debit card number.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Protected Class Information.”</span>Characteristics of protected classifications under California or federal law, such as age (date of birth), gender, gender identity, or sex, and disabilities such as physical limitations.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Transaction Information.”</span>Commercial information, such as transaction information and purchase history.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Online Use Information.”</span>Internet or network activity information, such as history of online Equipment use, including frequency and length of workouts, workout start and end times, exercises performed, and your interactions with our Services and other users with whom you share the Equipment or with whom you connect and interact through the Services.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Geolocation Data.”</span>Geolocation data such as approximate location derived from IP address.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Audio/Video Data.”</span>Audio, electronic, and visual information, such as your profile picture, other images or videos you have uploaded to the Services, and telephone call recordings.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Inferences.”</span>Inferences drawn from any of the Personal Information listed above to create a profile about, for example, an individual’s fitness level, regimen, goals, and preferences.</span>
                </p>
            </div>
            <p className="card-text mb-5">
                We collect this Personal Information from you and the third-party devices you have chosen to integrate with our Services and from our data analytics providers, third-party ad networks, and joint marketing partners.
            </p>
            
            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Use of Personal Information</p>
            <p className="card-text mb-5">
                We may use this Personal Information for the purposes described in “Use of Personal Information” and “Other Uses and Disclosures,” above.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Disclosure of Personal Information</p>
            <p className="card-text">
                We share Personal Information with the following categories of third parties:
            </p>
            <div className="card-text mb-5">
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Service Providers.”</span>Our trusted third-party service providers carry out activities at our direction, such as website hosting, data analysis, payment processing, order fulfillment, information technology and related infrastructure provision, customer service, email delivery, auditing, and other services.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Connected Applications.”</span>If you enable third-party products or applications, such as Apple Health or Strava, to track your workouts with our Services, we share Personal Information with the providers of those third-party products and applications.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Ad Networks.”</span>We share Personal Information with third-party ad networks to permit them to serve advertisements regarding goods and services that may be of interest to you when you access and use the Services and other websites or online services.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Equipment Owner.”</span>When you log in to your account on Equipment that you do not own, we share your Personal Information with the Equipment owner; in this case, the information shared with the Equipment owner may include your name, email, Equipment use history and metrics.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Social Media.”</span>When you connect your social media account and share your activity, we share Personal Information with your friends associated with your social media account, other website users and your social media account provider. By connecting your Services account and your social media account, you authorize us to share information with your social media account provider, and you understand that the use of the information we share will be governed by the social media site’s privacy policy.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Public Audience.”</span>When you post information and materials on message boards, chat, profile pages, blogs and other services on which you are able to post. Please note that any information you post or disclose through these services will become public information.</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span><span className="fw-bold pe-1">“Legal Authorities.”</span>We may share Personal Information to cooperate with public and government authorities, including law enforcement, and to protect and defend our legal rights and those of others.</span>
                </p>
            </div>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Sale of Personal Information</p>
            <p className="card-text mb-5">
                We have not “sold” Personal Information for purposes of the CCPA, including, to our knowledge, the Personal Information of minors.  For purposes of this Addendum, “sold” or “sale” means the disclosure of Personal Information for monetary or other valuable consideration, subject to certain exceptions.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Your CCPA Rights</p>
            <p className="card-subtitle mb-3 fw-bold text-decoration-underline">Requests to Know and Delete</p>
            <p className="card-text">
                If you are a California resident, you may make the following requests:
            </p>
            <p className="card-text"> (1) “Request to Know” </p>
            <p className="card-text">
                You may request that we disclose to you the following information covering the 12 months preceding your request:
            </p>
            <div className="card-text">
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>The categories of Personal Information we collected about you and the categories of sources from which we collected such Personal Information;</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>The specific pieces of Personal Information we collected about you;</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>The business or commercial purpose for collectingPersonal Information about you; and</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>The categories of Personal Information about you that we otherwise shared or disclosed, and the categories of third parties with which we shared or to which we disclosed such Personal Information (if applicable).</span>
                </p>
            </div>
            <p className="card-text"> (2) “Request to Delete” </p>
            <p className="card-text">
                You may request that we delete Personal Information we collected from you.
            </p>
            <p className="card-text mb-5">
                To make a Request to Know or Request to Delete, please contact us.  We will verify and respond to your request consistent with applicable law.  We may need to request additional Personal Information from you, such as name, email address, state of residency, and zip code, in order to verify your identity and protect against fraudulent requests.  If you maintain a password-protected account with us, we may verify your identity through our existing authentication practices for your account and require you to re-authenticate yourself before disclosing or deleting your Personal Information. If you make a Request to Delete, we may ask you to verify your request before we delete your Personal Information.  We will respond to your Request to Know or Request to Delete consistent with applicable law.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Right to Non-Discrimination</p>
            <p className="card-text mb-5">
                You have the right to be free from unlawful discrimination for exercising your rights under the CCPA.
            </p>

            <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">Authorized Agents</p>
            <p className="card-text">
                If you want to make a request as an authorized agent on behalf of a California resident, you may use the submission methods noted above.  As part of our verification process, we may request that you provide, as applicable:
            </p>
            <div className="card-text">
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>Proof of your registration with the California Secretary of State to conduct business in California;</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>A power of attorney from the California resident pursuant to Probate Code sections 4000-4465;</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>Written permission that the California resident has authorized you to make a request on the resident’s behalf.  This permission must be signed (via physical or e-signature) by the California resident.</span>
                </p>
            </div>
            <p className="card-text">
                If you are making a Request to Know or a Request to Delete on behalf of a California resident and have not provided us with a power of attorney from the resident pursuant to Probate Code sections 4000-4465, we may also require the resident to:
            </p>
            <div className="card-text mb-5">
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>Provide you with a written permission signed by the resident to make the Request to Know or Request to Delete on the resident’s behalf;</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>Verify the resident’s own identity directly with us;</span>
                </p>
                <p className="d-flex align-items-top">
                    <span className="pe-2">&#8226;</span>
                    <span>Directly confirm with us that the resident provided you permission to submit the Request to Know or Request to Delete.</span>
                </p>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <div className="container-fluid bg-dark position-relative text-light py-5" id="scroll-to-footer">
                <div className="container">
                    <div className="row align-items-lg-center">
                        <div className="col col-lg-auto">
                            <p className="h6 fs-obviously">Contact</p>
                            <p className="small fw-normal mb-0 mt-4">
                                <a className="link link-light text-decoration-none" rel="noopener noreferrer" target='_blank' href="mailto:info@framefitness.com">info@framefitness.com</a>
                            </p>
                            <p className="small fw-normal my-2">
                                Frame Innovative Technologies Corp. <br />
                                7694 Colony Palm Dr <br />
                                Boynton Beach <br />
                                Florida 33436 -1312 <br />
                            </p>
                            {/* <p className="small fw-normal my-2">
                                Frame Innovative Technologies Corp. <br />
                                265 S. Western Avenue <br />
                                P.O. Box #741940 <br />
                                Los Angeles, CA 90004-9998 <br />
                            </p> */}
                        </div>
                        <div className="col col-xl-8 col-lg-6 text-lg-center text-end">
                            <img src={require('../../assets/icons/frame-abreviated-logo-white.png').default}
                                alt="Site Logo" className="img-fluid my-lg-4 mt-0 mb-4 footer-site-icon" />
                        </div>
                    </div>
                    <div className="row d-flex align-items-end justify-content-between">
                        <div className="col-sm">
                            <a href="https://www.facebook.com/Frame-Reformer-276338430872027/" className="btn border-0 btn-circle me-3" rel="noopener noreferrer" target="_blank">
                                <img src={require('../../assets/icons/fb.svg').default} alt="Facebook Icon" />
                            </a>
                            <a href="https://twitter.com/framereformer" className="btn border-0 btn-circle me-3" rel="noopener noreferrer" target="_blank">
                                <img src={require('../../assets/icons/twitter.svg').default} alt="Twitter Icon" />
                            </a>
                            <a href="https://instagram.com/framereformer" className="btn border-0 btn-circle me-3" rel="noopener noreferrer" target="_blank">
                                <img src={require('../../assets/icons/insta.svg').default} alt="Instgram Icon" />
                            </a>
                        </div>
                        <div className="col-sm text-end mt-5">
                            <div className="row align-items-center justify-content-between"
                                style={{
                                    flexDirection: screen.width <= 575 ? 'row-reverse' : 'row'
                                }}>
                                <div className="col-auto col-sm-12">
                                    <p className="link link-light text-decoration-underline 
                                        text-capitalize px-0 me-0 me-sm-2 mb-sm-3 mb-0 d-sm-inline-block" style={{
                                            cursor: 'pointer'
                                        }} onClick={(e) => handleModal(e.target.dataset.id)} data-id="privacy">
                                        Privacy Policy
                                    </p>
                                    <p className="link link-light text-decoration-underline 
                                        text-capitalize px-0 ms-0 ms-sm-2 mb-sm-3 mb-0 d-sm-inline-block" style={{
                                            cursor: 'pointer'
                                        }} onClick={(e) => handleModal(e.target.dataset.id)} data-id="terms">
                                        Terms of Service
                                    </p>
                                </div>
                                <div className="col col-sm-12">
                                    <p className="mb-0 text-sm-end text-start">© 2021 Frame Innovative Technologies Corporation <br /> Patent pending</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalExtended open={open} onClose={handleModal}>
                <div className="card m-0 p-0 border-0 rounded-0">
                    {
                        dataID === 'privacy' ? PrivacyPolicy : TermsofService
                    }
                </div>
            </ModalExtended>
        </React.Fragment>
    );
};

export default Footer;