import React from 'react';
import PageVisibility from 'react-page-visibility'
import Ticker from 'react-ticker';

export const TickerExtended = ({ text, subtext, separator = true, customClass = '' }) => {
    const [pageIsVisible, setPageIsVisible] = React.useState(false);

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible);
    };

    React.useEffect(() => {
        setTimeout(() => {
            setPageIsVisible(true);
        }, 500);
    }, []);

    return (
        <PageVisibility onChange={handleVisibilityChange}>
            {pageIsVisible && (
                <div
                    className={`${customClass} marquee-bg fs-obviously text-uppercase`}
                >
                    <Ticker offset={5}>
                        {({ index }) => (
                            <>
                                <div key={index} className="d-flex align-items-center justify-content-space-evenly py-2 text-nowrap">
                                    {
                                        text instanceof Array ? <React.Fragment>
                                            {
                                                text.length > 0 && text.map((content, index) => (
                                                    <React.Fragment key={index}>
                                                        <span className={`small text-dark mb-0`}>{content.toString().trim()}</span>
                                                        {separator && <span className="small px-2">&#10038;</span>}
                                                    </React.Fragment>
                                                ))
                                            }
                                        </React.Fragment> : <React.Fragment>
                                            <span className={`small text-dark mb-0`}>{text}</span>
                                            {separator && <span className="small px-2">&#10038;</span>}
                                            {subtext && (
                                                <span className={`small text-dark mb-0`}>{subtext}</span>
                                            )}
                                            {subtext && separator && (
                                                <span className="small px-2">&#10038;</span>
                                            )}
                                        </React.Fragment>
                                    }
                                </div>
                            </>
                        )}
                    </Ticker>
                </div>
            )}
        </PageVisibility>
    );
};