import React, { useRef } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';


const ModalExtended = ({ onClose, open, children }) => {
    const modalContentRef = useRef(null)
    return (
        <Modal open={open} onClose={onClose} center
            classNames={{
                overlay: 'customizeOverlay',
                modal: 'p-0 modal-xl',
            }} initialFocusRef={modalContentRef}>
            <div className="modal-body p-0" ref={modalContentRef}>
                {children}
            </div>
        </Modal>
    );
};

export default ModalExtended;