import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ShopContext } from "../../context/shop";
import { useScreen } from "../../context/screen";

const Shipping = (props) => {
  const { checkout, updateShippingAddress } = React.useContext(ShopContext);
  const screen = useScreen();

  const shippingSchema = Yup.object().shape({
    first_name: Yup.string().trim().min(2, "First name must be between 2 - 50 characters long.").max(50, "First name must be between 2 - 50 characters long.").required("Please enter your first name"),
    last_name: Yup.string().trim().min(2, "Last name must be between 2 - 50 characters long.").max(50, "Last name must be between 2 - 50 characters long.").required("Please enter your last name"),
    address_1: Yup.string().trim().required("Please enter your correct shipping address").max(30, "Address should not exceed 30 characters!"),
    address_2: Yup.string().trim().max(30, "Address should not exceed 30 characters!"),
    city: Yup.string().trim().required("Please enter your city name"),
    state: Yup.string().trim().required("Please enter your state name"),
    email: Yup.string().trim().email("Please enter a valid email address").required("Please enter your email address"),
    phone: Yup.string().trim().required("Please enter your phone number").min(10, "The phone number must be between 10 - 12 characters long.").max(12, "The phone number must be between 10 - 12 characters long."),
    country: Yup.string().trim().required('Please select a your country'),
    postal_code: Yup.string().trim().required("Please enter your Zip code/Postal code").min(5, "Postal code must be between 5 - 9 characters long").max(9, "Postal code must be between 5 - 9 characters long"),
  });

  const handleKeyInput = event => {
    if (!(/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab|Shift|End|Home|Ctrl)/.test(event.key))) {
      event.preventDefault();
      return;
    }
  }

  const [countryCode, setCountryCode] = useState();
  const listOfCodes = ["+1", "+44", "+52"]
  const getCountryCode = value => {
    let updateCode = null;
    switch (value) {
      case "United State Of America":
      case "Canada":
        updateCode = "+1";
        break;
      case "United Kingdom":
        updateCode = "+44";
        break;
      case "Mexico":
        updateCode = "+52";
        break;
      default:
        updateCode = null;
        break;
    }

    setCountryCode(updateCode);
  }


  React.useEffect(() => {
    getCountryCode(checkout.shipping_information?.country);
  }, [checkout])

  return (
    <div className="my-3">
      <h5 className="fs-obviously">Shipping Information</h5>

      <Formik
        initialValues={{
          first_name: checkout.shipping_information?.first_name || "",
          last_name: checkout.shipping_information?.last_name || "",
          address_1: checkout.shipping_information?.address_1 || "",
          address_2: checkout.shipping_information?.address_2,
          city: checkout.shipping_information?.city || "",
          country: checkout.shipping_information?.country || "",
          state: checkout.shipping_information?.state || "",
          postal_code: checkout.shipping_information?.postal_code || "",
          email: checkout.shipping_information?.email || "",
          phone: checkout.shipping_information?.phone?.replace(listOfCodes.find(code => checkout.shipping_information.phone.includes(code)), "") || "",
        }}
        validationSchema={shippingSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          values.address_2 = values.address_2 === "" ? undefined : values.address_2
          updateShippingAddress(values, countryCode);
          props.nextStep()
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row g-2 align-items-start mt-4">
              <div className="col-6 my-2">
                <input
                  type="text"
                  className="form-control py-2 "
                  name="first_name"
                  autoFocus={true}
                  helpertext={touched.first_name && errors.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  placeholder="First Name"
                />
                <div className={`invalid-feedback ${errors.first_name && touched.first_name && 'd-block'}`}>
                  {errors.first_name && touched.first_name && (
                    <small className="small">
                      * {errors.first_name}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-6 my-2">
                <input
                  type="text"
                  className="form-control py-2"
                  placeholder="Last Name"
                  name="last_name"
                  helpertext={touched.last_name && errors.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                />
                <div className={`invalid-feedback ${errors.last_name && touched.last_name && 'd-block'}`}>
                  {errors.last_name && touched.last_name && (
                    <small className="small">
                      * {errors.last_name}
                    </small>
                  )}
                </div>
              </div>

              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control py-2"
                  placeholder="Shipping Address"
                  name="address_1"
                  helpertext={touched.address_1 && errors.address_1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_1}
                />
                <div className={`invalid-feedback ${errors.address_1 && touched.address_1 && 'd-block'}`}>
                  {errors.address_1 && touched.address_1 && (
                    <small className="small">
                      * {errors.address_1}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control py-2"
                  placeholder="Apartment, suite, etc. (optional)"
                  name="address_2"
                  helpertext={touched.address_2 && errors.address_2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_2}
                />
                <div className={`invalid-feedback ${errors.address_2 && touched.address_2 && 'd-block'}`}>
                  {errors.address_2 && touched.address_2 && (
                    <small className="small">
                      * {errors.address_2}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-4 my-2">
                <select
                  className="form-select py-2"
                  aria-label="Country/Region"
                  name="country"
                  helpertext={touched.country && errors.country}
                  onChange={(event) => {
                    getCountryCode(event.target.value)
                    handleChange(event)
                  }}
                  onBlur={handleBlur}
                  value={values.country ?? "0"}>
                  <option value="" label="Country/Region" />
                  <option value="United State Of America"> United State Of America </option>
                  <option value="Canada"> Canada </option>
                  {/* <option value="United Kingdom"> United Kingdom </option>
                  <option value="Mexico"> Mexico </option> */}
                </select>
                <div className={`invalid-feedback ${errors.country && touched.country && 'd-block'}`}>
                  {errors.country && touched.country && (
                    <small className="small">
                      * {errors.country}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-8 my-2">
                <div className="input-group mb-2">
                  {
                    countryCode &&
                    <div className="input-group-prepend">
                      <div className="input-group-text py-2">{countryCode}</div>
                    </div>
                  }
                  <input
                    disabled={!countryCode}
                    type="text"
                    className="form-control py-2"
                    placeholder="Phone Number (without dashes)"
                    name="phone"
                    helpertext={touched.phone && errors.phone}
                    onKeyDown={handleKeyInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  <div className={`invalid-feedback ${errors.phone && touched.phone && 'd-block'}`}>
                    {errors.phone && touched.phone && (
                      <small className="small">
                        * {errors.phone}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control py-2"
                  name="city"
                  helpertext={touched.city && errors.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  placeholder="City"
                />
                <div className={`invalid-feedback ${errors.city && touched.city && 'd-block'}`}>
                  {errors.city && touched.city && (
                    <small className="small">
                      * {errors.city}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 my-2">
                <input
                  type="text"
                  className="form-control py-2"
                  name="email"
                  placeholder="Email"
                  helpertext={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <div className={`invalid-feedback ${errors.email && touched.email && 'd-block'}`}>
                  {errors.email && touched.email && (
                    <small className="small">
                      * {errors.email}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 my-2">
                <div className="row g-2 align-items-start">
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control py-2"
                      placeholder="State"
                      name="state"
                      helpertext={touched.state && errors.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                    />
                    <div className={`invalid-feedback ${errors.state && touched.state && 'd-block'}`}>
                      {errors.state && touched.state && (
                        <small className="small">
                          * {errors.state}
                        </small>
                      )}
                    </div>

                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control py-2"
                      placeholder="Zip Code"
                      name="postal_code"
                      helpertext={touched.postal_code && errors.postal_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postal_code}
                    />
                    <div className={`invalid-feedback ${errors.postal_code && touched.postal_code && 'd-block'}`}>
                      {errors.postal_code && touched.postal_code && (
                        <small className="small">
                          * {errors.postal_code}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <button
              className={`btn btn-primary px-5 w-100
              ${screen.width <= 991
                  ? "fixed-bottom"
                  : "position-absolute bottom-0 start-50 translate-middle-x"
                }`}
              type="submit"
              disabled={isSubmitting || (checkout?.products && checkout?.products.length === 0)}
            >
              Next
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Shipping;
