import React, { Component, createContext } from "react";
import { publicAxios, privateAxios } from "../config/axiosIn";

const ShopContext = createContext();

class ShopProvider extends Component {

  state = {
    products: [],
    product: {},
    preCheckout: {},
    checkout: { billing_information: {}, shipping_information: {}, no_of_subscriptions: 0, professional_installation: true, same_billing: true, products: [] },
    isCartOpen: false,
    errors: [],
  };

  resetCart = () => {
    this.setState({ checkout: { no_of_subscriptions: 0, professional_installation: true, same_billing: true, products: [] } });

  }

  setErrors = (error) => {
    let err = this.state.errors;
    err.push(error)
    this.setState({ errors: err });

    setTimeout(() => {
      this.resetError();
    }, 3000)
  }

  resetError = () => {
    this.setState({ errors: [] });
  }

  createCheckout = async (cardInformation, hasBillingAddress = true, billingInformation, subscription = true) => {
    this.setState({ errors: [] });
    if(hasBillingAddress) {
      this.setState({
        ...this.state,
        checkout: {
          ...this.state.checkout,
          card: cardInformation,
          same_billing: hasBillingAddress,
          free_subscription: subscription,
          billing_information: this.state.checkout.shipping_information
        },
      });
    } else {
      this.setState({
        ...this.state,
        checkout: {
          ...this.state.checkout,
          card: cardInformation,
          same_billing: hasBillingAddress,
          free_subscription: subscription,
          billing_information: billingInformation
        },
      });
    }
    
    const orders = await privateAxios.post("/api/orders", this.state.checkout);
    return orders;

  };

  fetchCheckout = async () => {
    try {
      const preCheckout = await publicAxios.get("/api/pre-checkout");
      this.setState({ preCheckout: preCheckout.data });
    } catch (e) {
      console.error(e);
    }
  };

  addItemToCheckout = async (variantId, quantity) => {
    const lineItemsToAdd = {
      product_variant: variantId,
      quantity: parseInt(quantity) || 1,
    };

    let checkout = this.state.checkout;
    const currentIndex = checkout.products.findIndex((item) => item.product_variant === variantId);
    if (checkout.products.length > 0 && currentIndex !== -1) {
      checkout.products[currentIndex] = {
        product_variant: variantId,
        quantity: checkout.products[currentIndex].quantity + lineItemsToAdd.quantity,
      };
    } else {
      checkout.products.push(lineItemsToAdd)
    }

    checkout.no_of_subscriptions = checkout.no_of_subscriptions + lineItemsToAdd.quantity;
    this.setState({ checkout: checkout });
    this.openCart();
  };

  updateShippingAddress = async (data, country_code = undefined) => {
    if (this.state.checkout) {
      let shipment = data;
      shipment.phone =  country_code + shipment.phone;

      this.setState({
        ...this.state,
        checkout: {
          ...this.state.checkout,
          shipping_information: shipment
        },
      });
    }
  };

  updateCheckoutItems = async (data) => {

    let checkout = this.state.checkout;
    
    checkout.products = this.state.checkout.products.map((item) => {
      if (item.product_variant === data.product_variant) {
        return { ...item, quantity: data.quantity };
      }
      return item;
    });

    checkout.no_of_subscriptions = checkout.products.reduce(
      (a, b) => a + b.quantity,
      0
    );
    this.setState({ checkout: checkout });
  };

  removeItemfromCheckout = async (lineItemId, subscription = false) => {
    // Remove an item from the checkout
    let checkout = this.state.checkout;
    if (!subscription) {
      checkout.products = checkout.products.filter(
        (item) => item.product_variant !== lineItemId
      );
      
      checkout.no_of_subscriptions = checkout.products.reduce(
        (a, b) => a + b.quantity,
        0
      );
      this.setState({ checkout: checkout });
    } else {
      checkout.no_of_subscriptions = 0;
      this.setState({ checkout: checkout });
    }
  };

  updateSubscription = (quantity) => {
    let checkout = this.state.checkout;
    checkout.no_of_subscriptions = quantity;
    if (!quantity) {
      checkout.no_of_subscriptions = checkout.products.reduce(
        (a, b) => a + b.quantity,
        0
      );
    }
    this.setState({ checkout: checkout });
  };

  withInstallment = (sendProfessional = true) => {
    let checkout = this.state.checkout;
    checkout.professional_installation = sendProfessional;
    
    this.setState({ checkout: checkout });
  };

  setBillingAddress = (same_billing = true) => {
    let checkout = this.state.checkout;
    checkout.same_billing = same_billing;
    if(same_billing) {
      checkout.billing_information = checkout.shipping_information
    }

    this.setState({ checkout: checkout });
  }

  fetchAllProducts = async () => {
    try {
      const products = await publicAxios.get("/api/products");
      this.setState({ products: products.data });
    } catch (e) {
      console.error(e);
    }
  };

  closeCart = () => {
    this.setState({ isCartOpen: false });
  };
  openCart = () => {
    this.setState({ isCartOpen: true });
  };

  setStatusSuccess = () => {
    this.setState({
        ...this.state,
        checkout: {
          ...this.state.checkout,
          status: "SUCCESS",
        },
      });
  }

  setOrderID = (orderId) => {
    this.setState({
      ...this.state,
      checkout: {
        ...this.state.checkout,
        id: orderId
      },
    });
  }

  render() {
    return (
      <ShopContext.Provider
        value={{
          ...this.state,
          fetchAllProducts: this.fetchAllProducts,
          setErrors: this.setErrors,
          resetError: this.resetError,
          fetchProductWithId: this.fetchProductWithId,
          closeCart: this.closeCart,
          openCart: this.openCart,
          addItemToCheckout: this.addItemToCheckout,
          fetchCheckout: this.fetchCheckout,
          updateCheckoutItems: this.updateCheckoutItems,
          removeItemfromCheckout: this.removeItemfromCheckout,
          updateShippingAddress: this.updateShippingAddress,
          createCheckout: this.createCheckout,
          updateSubscription: this.updateSubscription,
          withInstallment: this.withInstallment,
          setBillingAddress: this.setBillingAddress,
          resetCart: this.resetCart,
          setOrderID: this.setOrderID,
          setStatusSuccess: this.setStatusSuccess,
        }}
      >
        {this.props.children}
      </ShopContext.Provider>
    );
  }
}

const ShopConsumer = ShopContext.Consumer;
export { ShopConsumer, ShopContext };
export default ShopProvider;
