import React from 'react';
import { Link } from 'react-scroll';

import WavyTextAnimation from './WavyTextAnimation';

import "./FloatingComponent.css";

const FloatingComponent = ({ children, animation = undefined }) => {

    return (
        <React.Fragment>
            <div className="position-relative">
                <div className="left-side-elem top-50 start-0 translate-middle-y position-fixed mx-sm-3 mx-1">
                    <Link className="btn btn-circle ripple peace-symbole" to="scroll-to-footer" smooth={true} duration={5} />
                </div>

                <div className="right-side-elem position-fixed d-flex align-items-center">
                    <img
                        src={require("../../assets/icons/frame.png").default}
                        alt="Frame Logo"
                        className="m-0 mx-5 right-logo img-fluid"
                    />
                    <div className="right-text">
                        <WavyTextAnimation animation={animation} />
                    </div>
                </div>
                {children}
            </div>
        </React.Fragment>
    );
};

export default FloatingComponent;